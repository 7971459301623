export const Connection = {
  // produção

  url_client: 'http://localhost:3000',
  url_server: 'https://monkfish-app-4incq.ondigitalocean.app',
  // url_server: 'https://linkpages.ml',

  ENVIRONMENT: 'production', // dev or production
  PROJECT_VERCEL_HOMOLOG: 'offstore-next-homolog',
  PROJECT_VERCEL_PRODUCTION: 'offstore-next',
  TEAM_VERCEL: 'team_jxTeklVAuw8JNpKFZBZHQ0Zm',
  PROJECT_VERCEL: 'prj_HOZNVWUIogS6TOCoWWiEJMBfLgyS', // apenas para produção
  // desenvolvimento

  // url_client: 'http://localhost:3000',
  // url_server: 'http://localhost:3333',

  // Homologação

  // url_server: 'https://offstore-homolog.onrender.com',
  brasil_api: 'https://brasilapi.com.br/api',
  offstore_pay: 'https://offstore-pay-xefk.onrender.com',
  url_conversion_api: 'https://offstore-server.onrender.com',
  // url_server: 'https://offstore-merge-mcgt.onrender.com',
  URL_PAGBANK_CONNECT: 'https://connect.pagseguro.uol.com.br',
};

export const STRIPE_PK =
  'pk_live_51IQcuBIMlZnmWE6noX3IKpDgjq7Arbs0yOtpM2YKdDZPPZXcKRf3VSP53kpM6z7TzbWYBQ00bhy119mPkFV0ZgcR00dn7yHHjC';

export const STRIPE_PK_HOMOLOG =
  'pk_test_51IQcuBIMlZnmWE6nlQO7Q58ThBVAaeEiwf2Sj93zWkrFnyK2xtchOlN2KCBmIbUjxcClra9chcWhJgfVPNngmqob00yV9Eg95v';

export const TOKEN_VERCEL = 'YzlRmHllk83yspxWeXlm6f3y';
// export const STRIPE_PK =
//   'pk_test_51IQcuBIMlZnmWE6nlQO7Q58ThBVAaeEiwf2Sj93zWkrFnyK2xtchOlN2KCBmIbUjxcClra9chcWhJgfVPNngmqob00yV9Eg95v';

export const CONVERSION_API_META_PIXEL_ID = '1775048205980627';
export const CONVERSION_API_META_TOKEN =
  'EAAWqXmVfyYMBO8l4euZCZAhwBSF7VgiGq6lJGEq0oXrR2RH8PwRriqETzw4IaolbAykQhTgsp21cUCGAjDqmWKQMRSvZBb66uZAkk02hPt8YcBQTjEzXQN1hgoIGIkYgvl32zSgl2W2wBwnS3xBb992dBDmReKwQ1ImDrTxtD0ztsiZA0OE2l2EY2M9hl4hV2JAZDZD';
